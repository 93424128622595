<template>
  <div class="">
    Knowledge..
  </div>
</template>

<script>
export default {
  name: 'Knowledge',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>

</style>
